import {getCookie, setCookie} from "./cookie";


export function schemeToggle(userRequest = false) {

    let theme = getCookie("sc_theme");
    // check if user request
    if (theme) {
        if (userRequest) {
            theme = theme === "dark" ? "light" : "dark";
            setCookie("sc_theme", theme, 30);
        }
    } else {
        // first visit: get current system scheme and set to cookies
        theme = (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) ? "dark" : "light";
        setCookie("sc_theme", theme, 30);
    }

    let switchCheckbox = $('.switch-theme-checkbox');
    if (theme === "dark") {
        $(document.body).addClass("dark");
        switchCheckbox.prop('checked',true);
        switchCheckbox.parent().find('label').text('Nebe');

    } else {
        $(document.body).removeClass("dark");
        switchCheckbox.prop('checked',false);
        switchCheckbox.parent().find('label').text('Peklo');
    }

    // call all possible component toggles
    navbarToggle(theme);
    dropdownMenuToggle(theme);
    buttonOutlineToggle(theme);
    tableToggle(theme);

}


function navbarToggle(theme) {
    if (theme === "dark") {
        $('.navbar').removeClass("navbar-light bg-light").addClass("navbar-dark bg-dark");
    } else {
        $('.navbar').removeClass("navbar-dark bg-dark").addClass("navbar-light bg-light");
    }
}

function dropdownMenuToggle(theme) {
    if (theme === "dark") {
        $('.dropdown-menu').addClass("dropdown-menu-dark");
    } else {
        $('.dropdown-menu').removeClass("dropdown-menu-dark");
    }
}

function buttonOutlineToggle(theme) {
    if (theme === "dark") {
        $('.btn-outline-dark').removeClass("btn-outline-dark").addClass("btn-outline-light");
    } else {
        $('.btn-outline-light').removeClass("btn-outline-light").addClass("btn-outline-dark");
    }
}

function tableToggle(theme) {
    if (theme === "dark") {
        $('.table').addClass("table-dark");
    } else {
        $('.table').removeClass("table-dark");
    }
}
