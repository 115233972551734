/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
require('bootstrap');
// require('bootstrap/js/dist/collapse');

// import { Tooltip, Toast, Popover, Collapse } from 'bootstrap';


import './styles/global.scss';

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything

// import $ from 'jquery';

// import 'simplelightbox';
// import 'bs5-lightbox';

import 'lightgallery';

import './js/main';
// start the Stimulus application
import './bootstrap';
// import 'simplelightbox';