export function participantConversation(conversation) {
    // if (user) {
        let xhr = null;
        xhr = $.ajax({
            url: '/conversation-visited/' + conversation,
            success: function (data) {
                // let sceetElement = $('#sceet-container');
                // sceetElement.html(data);
            }
        });

    // }
}