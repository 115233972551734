import {schemeToggle} from "./partials/theme_toggler";
import {Collapse} from "bootstrap";
// import {SimpleLightbox} from "simplelightbox/dist/simple-lightbox";
// new SimpleLightbox
// import lightGallery from 'lightgallery';

import {Application} from "@hotwired/stimulus";
import Lightbox from "stimulus-lightbox";
import scswup_controller from "../controllers/scswup_controller";

import {Popover} from "bootstrap";
import {Tooltip} from "bootstrap";
import {participantConversation} from "./partials/ajax_calls";

const application = Application.start();
application.register("lightbox", Lightbox);

// navbar initialize
let navbarElement = document.getElementById('navbarCollapse')
let navCollapse = new Collapse(navbarElement, {
    toggle: false
})


$(document).ready(function () {
    schemeToggle();
    checkNewComments();
    display_ht();


});


// Hobart time
function display_h() {
    var refresh = 1000; // Refresh rate in milli seconds
    var mytime = setTimeout(display_ht, refresh);
}

function display_ht() {
    var x = new Date();
    let t = x.toLocaleTimeString(undefined, {timeZone: "Australia/Sydney", formatMatcher: "basic"});
    // let hobartTime = t.getHours()+':'+t.getMinutes()+
    let ctElement = document.getElementById('ct');
    if (ctElement) {
        document.getElementById('ct').innerHTML = t;
        display_h();
    }
}


function checkNewComments() {
    var element =
        document.getElementById("comments-block");
    // check tab-panes
    if (element && element.dataset.user) {
        var conversation = element.dataset.conversation;
        if (element.parentElement.classList.contains('tab-pane')) {
            if (element.parentElement.classList.contains('show')) {
                participantConversation(conversation);
            }
            var tabEl = document.querySelector('a[href="#conversation"]');
            if (tabEl) {
                tabEl.addEventListener('shown.bs.tab', function (event) {
                    participantConversation(conversation);
                });
            }
        } else {
            // typical conversation
            participantConversation(conversation);
        }
    }
}






// theme switcher on change
$('.switch-theme-checkbox').change(function () {
    schemeToggle(true);
});

// reload page simulation
document.addEventListener('swup:contentReplaced', (event) => {
    if (navCollapse._isShown() === true) {
        navCollapse.hide();
    }

    schemeToggle();
    checkNewComments();
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
    });
});

// click out of the navbar for close it
$(document).click(function (event) {
    if (!$(event.target).is('.navbar-collapse *')) {
        navCollapse.hide();
    }
});

// remove readonly from input
$('.edit-readonly').click(function (event) {
    event.preventDefault();
    let targetInputClass = this.dataset.editClass;
    let target = document.getElementsByClassName(targetInputClass);
    target[0].removeAttribute('readonly');
    target[0].focus();
});


// popover initialize
var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
})
